import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';

const Faq = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="FAQ"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "80px"}}>
                            <h1 style={{marginBottom: "50px"}}>FAQ</h1>
                            <h3 style={{marginTop: "20px"}}>How long does it take to receive an SMS sent through
                                SecretSMS?</h3>
                            <p>SecretSMS offers the fastest receiving time of any messaging service. All messages
                                sent using our service will be almost instantaneously received. Please note, that this
                                may vary depending on the mobile service provider.</p>
                            <h3 style={{marginTop: "20px"}}>What number will appear on the phone that receives the
                                SMS?</h3>
                            <p>If you wish to start a SMS conversation, SecretSMS will create a virtual phone number
                                and link it to your account. However, if you wish to remain anonymous you may do so
                                by choosing to send an SMS using a custom name. In this case, no number will appear
                                on the recipients phone, only the name you chose. </p>
                            <h3 style={{marginTop: "20px"}}>Will my identity remain undisclosed when sending an SMS from
                                SecretSMS?</h3>
                            <p>Yes. Your identity will be kept undisclosed at all times unless you wish to reveal your
                                identity. Your information is completely safe and secure while using our services. </p>
                            <h3 style={{marginTop: "20px"}}>Can I cancel my subscription to SecretSMS at any time?</h3>
                            <p>Yes. You can cancel your subscription at any time. </p>
                            <h3 style={{marginTop: "20px"}}>I forgot my password, can you help me?</h3>
                            <p>Yes, you can change you password inside app.secret-sms.com under settings tab.</p>
                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default Faq;
